import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import { heading1 } from "@styles/ui"
import Section from "@components/section"
import GradientText from "@components/gradientText"
import PrimaryCta from "@components/PrimaryCta"
import MyWavVideoSection from "@components/MyWavVideoSection"

// Assets
import nightlyDose from "@images/dosing/nightly-dose.svg"
import doseAmount from "@images/dosing/dose-amount.svg"
import doseTiming from "@images/dosing/dose-timing.svg"

const Dosing = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="orange">
      <Seo
        title="Dosing Instructions & Preparation for Taking XYWAV | XYWAV®"
        description="Learn how to prepare and take Xywav (calcium, magnesium, potassium, and sodium oxybates) and how the dosage can be individualized to help find what's right for you. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="Dosing"
        gradient="purple-red"
        jumpLinks={[
          { text: "Taking XYWAV", link: "#taking-xywav" },
          {
            text: "Dose Adjustments",
            link: "#dose",
          },
          {
            text: "Storage",
            link: "#storage",
          },
        ]}
      >
        Learning how to prepare and take XYWAV
      </StaticHero>

      <Section id="taking-xywav" bgColour={tw`bg-lilac`}>
        <Container grid>
          <div tw="col-span-full lg:text-center">
            <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md) lg:before:(mb-6 w-[135px] mx-auto)"></div>
            <h2
              css={[
                heading1,
                tw`text-[22px] leading-[28px] text-merlin mb-[30px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
              ]}
            >
              Follow the instructions for proper preparation and dosing
            </h2>
          </div>
          <p tw="col-span-full font-black text-[20px] leading-normal mb-2.5 lg:(text-[22px] leading-[125%] mb-[25px] text-center)">
            When you receive your first shipment of XYWAV, you&apos;ll receive
            the Patient Quick Start Guide along with your medication and
            detailed preparation and dosing instructions. Here&apos;s an
            overview of what you&apos;ll need to know to take XYWAV:
          </p>
          <ul tw="col-span-full list-disc px-4 lg:col-span-6">
            <li tw="mb-2.5">
              XYWAV is taken at night and divided into 2 doses
            </li>
            <li tw="mb-2.5">
              Prepare your 2 doses at the same time, prior to bedtime, and put
              them in a safe and secure place. Before you take or give XYWAV,
              each dose should be mixed with approximately ¼ cup of water in the
              empty pharmacy containers included in the shipment
            </li>
            <li tw="mb-2.5">
              Take your first dose while in bed and lie down immediately
              <ul tw="mt-2.5 list-disc ml-5">
                <li>
                  XYWAV can cause sleep very quickly, without feeling drowsy.
                  Some people fall asleep within 5 minutes and most fall asleep
                  within 15 minutes
                </li>
              </ul>
            </li>
            <li tw="mb-2.5">
              Take the second dose 2½ to 4 hours later, as instructed by your
              doctor, while in bed and lie down immediately after taking the
              medication. You should remain in bed after taking each dose of
              XYWAV
            </li>
          </ul>
          <ul tw="col-span-full list-disc px-4 lg:col-span-6">
            <li tw="mb-2.5">
              If you miss the second dose, skip it
              <ul tw="mt-2.5 list-disc ml-5">
                <li>Never take 2 doses of XYWAV at one time</li>
              </ul>
            </li>
            <li tw="mb-2.5">
              Wait at least 2 hours after eating before taking XYWAV
            </li>
            <li tw="mb-2.5">
              For children, give the first XYWAV dose at bedtime or after an
              initial period of sleep, while your child is in bed and have them
              lie down immediately. Give the second XYWAV dose 2½ to 4 hours
              after the first XYWAV dose. You may want to set an alarm clock to
              make sure you wake up to give the second XYWAV dose. Your child
              should remain in bed after taking the first and second doses of
              XYWAV
            </li>
          </ul>
        </Container>
      </Section>

      <Section id="dose">
        <Container grid addedStyles={tw`lg:gap-y-24`}>
          <div tw="col-span-full mb-10 lg:(col-span-3 mb-0)">
            <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md mx-auto) lg:before:(mb-6 w-[135px] mx-[unset])"></div>
            <h2
              css={[
                heading1,
                tw`text-[1.5625rem] leading-[120%] text-merlin mb-[15px] pb-0 text-center lg:(mb-[25px] text-left text-[1.5625rem])`,
              ]}
            >
              Individualize your XYWAV dose with your doctor
            </h2>
            <p tw="text-center text-base lg:text-left">
              When finding the dose that works best for you, your doctor can
              tailor your treatment to help XYWAV work as well as possible by
              adjusting:
            </p>
          </div>
          <div tw="col-span-full flex flex-col items-center justify-start mb-20 lg:(col-span-3 mb-0)">
            <img
              src={nightlyDose}
              alt=""
              tw="w-[118px] mb-3.5 lg:(w-[150px] mb-4.5)"
            />
            <p tw="font-black text-[20px] text-center lg:(text-[22px] leading-[125%])">
              Your total
              <br tw="hidden lg:block" /> nightly&nbsp;dose
            </p>
          </div>
          <div tw="col-span-full flex flex-col items-center justify-start mb-20 lg:(col-span-3 mb-0)">
            <img
              src={doseAmount}
              alt=""
              tw="w-[118px] mb-3.5 lg:(w-[150px] mb-4.5)"
            />
            <p tw="font-black text-[20px] text-center px-9 lg:(text-[22px] leading-[125%])">
              How much medicine you take with your first and second&nbsp;doses
            </p>
          </div>
          <div tw="col-span-full flex flex-col items-center justify-start mb-14 lg:(col-span-3 mb-0)">
            <img
              src={doseTiming}
              alt=""
              tw="w-[118px] mb-3.5 lg:(w-[150px] mb-4.5)"
            />
            <p tw="font-black text-[20px] text-center lg:(text-[22px] leading-[125%])">
              The timing between your first and second&nbsp;doses
            </p>
          </div>
          <div tw="col-span-full flex items-center mb-8 lg:(col-start-4 col-span-6)">
            <div>
              <GradientText>
                <p tw="text-center text-[20px] lg:(text-[22px] leading-[125%])">
                  With XYWAV, your doctor can adjust the dose of your medication
                  at increments as low as 0.25&nbsp;g.
                </p>
              </GradientText>
              <div tw="text-left mx-auto max-w-[28rem] mt-8 lg:(mt-5)">
                <p tw="mb-1.5 text-base">
                  <strong>Recommended starting dose:</strong> 4.5 g divided into
                  2 doses
                </p>
                <p tw="mb-1.5 text-base">
                  <strong>Recommended maximum dose:</strong> 9 g divided into 2
                  doses
                </p>
                <p tw="text-base">
                  <strong>Dosing increments: </strong> 0.25 g - 1.5 g, as often
                  as once per night per week
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section id="storage" bgColour={tw`lilac-gradient-vertical`}>
        <Container grid>
          <div tw="col-span-full mb-8 lg:(col-start-2 col-span-5 flex flex-col justify-center mb-0)">
            <div tw="before:(gradientRed block relative w-[100px] mb-6 h-1.5 rounded-md) lg:before:(w-[135px])"></div>
            <h2
              css={[
                heading1,
                tw`text-[1.5625rem] leading-[120%] text-merlin mb-[15px] pb-0 lg:(mb-[25px] text-[1.5625rem])`,
              ]}
            >
              XYWAV Storage
            </h2>
            <ul tw="list-disc pl-4">
              <li tw="mb-2.5">
                Prepared doses should be taken within 24 hours after mixing
              </li>
              <li>Store XYWAV in a safe place, away from children and pets</li>
            </ul>
          </div>
          <div tw="col-span-full lg:(col-start-8 col-span-4)">
            <GatsbyImage
              image={getImage(data.bottle)}
              alt="An image of the XYWAV medicine bottle"
            />
          </div>
        </Container>
      </Section>

      <Section>
        <Container addedStyles={[tw`text-center`]}>
          <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-[135px])"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
            ]}
          >
            XYWAV Medication Guide
          </h2>
          <p tw="mb-[25px]">
            Read the Instructions for Use in the Medication Guide for detailed
            instructions on preparing and taking XYWAV.
          </p>
          <div tw="flex flex-col lg:(flex-row justify-center)">
            <div tw="w-full mb-[25px] max-w-[340px] mx-auto px-3 lg:(mx-[unset] mb-0 max-w-[300px])">
              <PrimaryCta
                url={"https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"}
                isExternal
                noInterstitial
                mdSmall
              >
                GET THE GUIDE (ENGLISH)
              </PrimaryCta>
            </div>
            <div tw="w-full max-w-[340px] mx-auto px-3 lg:(mx-[unset] max-w-[300px])">
              <PrimaryCta
                url={"https://pp.jazzpharma.com/pi/xywav.sp.MG.pdf"}
                isExternal
                noInterstitial
                mdSmall
              >
                GET THE GUIDE (SPANISH)
              </PrimaryCta>
            </div>
          </div>
        </Container>
      </Section>

      <MyWavVideoSection
        thumbnail={data.videoThumbnail}
        theme="narcolepsy"
        header={
          <>
            Watch and learn about the myWAV app: Individualized support at your
            fingertips
          </>
        }
        disclaimer
      >
        Once your doctor has prescribed XYWAV, you can register for and download
        the myWAV app. myWAV can help you get started and offers support
        throughout your treatment journey.
      </MyWavVideoSection>
    </Layout>
  )
}

export default Dosing

export const query = graphql`
  query {
    bottle: file(relativePath: { eq: "dosing/xywav-medicine-bottle.png" }) {
      childImageSharp {
        gatsbyImageData(width: 864, placeholder: NONE)
      }
    }
    videoThumbnail: file(
      relativePath: { eq: "video-thumbnails/mywav-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
